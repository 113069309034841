import React from "react"
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"
import parse from "html-react-parser"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import NewsItem from "../components/news/news-item"
import TagItem from "../components/tag-item"

const BlogPostTemplate = ({ data: { previous, next, post } }) => {
  const featuredImage = {
    fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: post.featuredImage?.node?.alt || ``,
  }

  return (
    <Layout>
      <Seo title={post.title} description={post.excerpt} />

      <article
        className="m-auto max-w-7xl"
        itemScope
        itemType="http://schema.org/Article"
      >
        <div className="flex">
          <div className="hidden md:block w-1/3 my-4 pr-10 pt-4">
            <span className="post-item-content mt-2 block text-2xl md:text-2xl leading-8 font-bold tracking-tight text-gray-700 sm:text-4xl">
              {post.project_category.project.title}
            </span>
            <p className="font-light text-gray-500 mt-2">{post.date}</p>
            <div className="mt-6 flex flex-wrap">
              {post.tags.nodes.map(tag => {
                return (
                  <div key={tag.id} className="inline m-0.5">
                    <TagItem tag={tag} />
                  </div>
                )
              })}
            </div>

            <div className="mt-16 border-t border-gray-200 pt-16">
              <div className="s9-widget-wrapper block"></div>
            </div>
            <Link to="/latest-news" rel="prev" className="mt-16 block">
              ← Back
            </Link>
          </div>

          <div className="md:w-2/3">
            <h1 className="my-2 md:my-4">
              <span className="post-item-content mt-2 block text-2xl md:text-5xl leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl mx-4 md:mx-0">
                {parse(post.title)}
              </span>
            </h1>

            {featuredImage?.fluid && (
              <Image
                className="h-96"
                fluid={featuredImage.fluid}
                alt={featuredImage.alt}
              />
            )}
            {!!post.content && (
              <div className="mx-4 md:mx-0 mt-4 md:mt-8 text-gray-800">
                {parse(post.content)}
              </div>
            )}
          </div>
        </div>
      </article>

      {/*<nav className="my-4 md:my-8">*/}
      {/*  <ul className="flex justify-between items-center">*/}
      {/*    <li className="m-4">*/}
      {/*      {previous && (*/}
      {/*        <Link to={`/latest-news/${previous.slug}`} rel="prev">*/}
      {/*          ← Previous*/}
      {/*        </Link>*/}
      {/*      )}*/}
      {/*    </li>*/}

      {/*    <li className="m-4">*/}
      {/*      {next && (*/}
      {/*        <Link to={`/latest-news/${next.slug}`} rel="next">*/}
      {/*          Next →*/}
      {/*        </Link>*/}
      {/*      )}*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*</nav>*/}
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      slug
      tags {
        nodes {
          id
          name
        }
      }
      date(formatString: "MMMM DD, YYYY")
      project_category {
        project {
          ... on WpProject {
            id
            title
          }
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      slug
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      slug
      title
    }
  }
`

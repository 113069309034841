import React from "react"
//

export default function TagItem({ tag }) {
  return (
    <span className="flex-wrap border-gray-200 border px-3 py-0.5 text-sm font-medium mb-4 tags mb-1">
      {tag.name}
    </span>
  )
}
